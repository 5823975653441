<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.salesByDayname')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <multi-list-select :list="machineData" option-value="machineId" option-text="name"
              :selected-items="selectedMachine" :placeholder="$t('message.all')" @select="onSelectMachine">
            </multi-list-select>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.selectDateTime") }} :
            <br />
            <date-time-picker v-model="searchData.datetime"></date-time-picker>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-2 my-3">
            <b-btn class="col-sm-12 col-md-12 ml-12 mr-3 my-1" variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
        <br />
      </b-form>


      <div class="row my-4"  v-if="series_overview[0].data != ''">
        <div class="col-sm-6 col-md-12 col-lg-8 my-3">
            <div id="chart" v-if="series_overview[0].data != ''">
              <apexchart type="line" height="350" :options="chartOptions_overview" :series="series_overview"></apexchart>
            </div>
        </div>

        <div class="col-sm-6 col-md-12 col-lg-4 my-4">
          <div class="table-responsive">
            <table class="table-grid">
              <thead style="text-align-last: center">
                <th style="width: 15%;"></th>
                <th style="width: 18%;" v-if="total_washer > 0">{{ $t("message.washer") }}</th>
                <th style="width: 18%;"  v-if="total_dryer > 0">{{ $t("message.dryer") }}</th>
                <th style="width: 18%;" v-if="total_solution > 0">{{ $t("message.solution") }}</th>
                <td style="width: 18%;">{{ $t("message.tote") }}</td>
              </thead>
              <tbody  style="text-align-last: right">
                <tr>
                  <td>{{$t('message.Sunday')}}</td>
                  <td v-if="total_washer > 0">{{ Number(Sunday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Sunday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Sunday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Sunday.washer + Sunday.dryer + Sunday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{$t('message.Monday')}}</td>
                  <td v-if="total_washer > 0">{{ Number(Monday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Monday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Monday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Monday.washer + Monday.dryer + Monday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{$t('message.Tuesday')}}</td>
                  <td v-if="total_washer > 0">{{ Number(Tuesday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Tuesday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Tuesday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Tuesday.washer + Tuesday.dryer + Tuesday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{$t('message.Wednesday')}}</td>
                  <td v-if="total_washer > 0">{{ Number(Wednesday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Wednesday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Wednesday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Wednesday.washer + Wednesday.dryer + Wednesday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{$t('message.Thursday')}}</td>
                  <td v-if="total_washer > 0">{{ Number(Thursday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Thursday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Thursday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Thursday.washer + Thursday.dryer + Thursday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{$t('message.Friday')}} </td>
                  <td v-if="total_washer > 0">{{ Number(Friday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Friday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Friday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Friday.washer + Friday.dryer + Friday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>{{$t('message.Saturday')}} </td>
                  <td v-if="total_washer > 0">{{ Number(Saturday.washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(Saturday.dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(Saturday.solution).toLocaleString() }}</td>
                  <td>{{ Number(Saturday.washer + Saturday.dryer + Saturday.solution).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td v-if="total_washer > 0">{{ Number(total_washer).toLocaleString() }}</td>
                  <td v-if="total_dryer > 0">{{ Number(total_dryer).toLocaleString() }}</td>
                  <td v-if="total_solution > 0">{{ Number(total_solution).toLocaleString() }}</td>
                  <td>{{ Number(total_washer + total_dryer + total_solution).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div clase="row" v-if="salesByDayname_groupProduct != ''">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 my-4" v-if="SaleByTypeWasher != ''">
          <h4 style="text-align: center;">{{ $t("message.washer") }}</h4>

          <div id="chart" v-if="SaleByTypeWasher != ''">
            <apexchart type="line" height="350" :options="chartOptions_washer" :series="series_washer"></apexchart>
          </div>

          <div class="table-responsive"  :style="daynameIntersection.length <= 3 ? 'padding-left: 20%; padding-right: 20%;' : 'padding-left: 15%; padding-right: 15%;' ">
            <table class="table-grid">
              <thead style="text-align-last: center">
                <th colspan="1">{{ $t("message.No") }}</th>
                <th colspan="1">{{ $t("message.productName") }}</th>
                <template v-for="row in daynameIntersection">
                  <th style="height: 50px; width: 8%;" v-if="row == 'Sunday'">{{$t('message.Sunday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Monday'">{{$t('message.Monday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Tuesday'">{{$t('message.Tuesday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Wednesday'">{{$t('message.Wednesday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Thursday'">{{$t('message.Thursday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Friday'">{{$t('message.Friday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Saturday'">{{$t('message.Saturday')}}</th>
                </template>
                <th colspan="1">{{ $t("message.tote") }}</th>
              </thead>
              <tbody>
                <template v-for="(saleData, index) in SaleByTypeWasher">
                  <tr>
                    <td style="text-align: center; ">
                      {{ index + 1}}
                    </td>
                    <td style="text-align: right; width: 25%; " >
                      {{ saleData.name }}
                    </td>
                    <template v-for="data in saleData.washer">
                      <td style="text-align: right" >
                        {{ Number(data.total).toLocaleString() }}
                      </td>
                    </template>
                    <td style="text-align: right; width: 28%; " >
                      {{ Number(saleData.total).toLocaleString() }}
                    </td>
                  </tr>
                </template>
                <td style="width: 3%; text-align: right;"></td>
                <td style="width: 35%; text-align: right;">{{ $t("message.tote") }}</td>
                <template v-for="row in daynameIntersection">
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Sunday'">{{ Number(Sunday.washer).toLocaleString() }}</td>
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Monday'">{{ Number(Monday.washer).toLocaleString() }}</td>
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Tuesday'">{{ Number(Tuesday.washer).toLocaleString() }}</td>
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Wednesday'">{{ Number(Wednesday.washer).toLocaleString() }}</td>
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Thursday'">{{ Number(Thursday.washer).toLocaleString() }}</td>
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Friday'">{{ Number(Friday.washer).toLocaleString() }}</td>
                  <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Saturday'">{{ Number(Saturday.washer).toLocaleString() }}</td>
                </template>
                <td  style="height: 50px; width: 8%; text-align: right;">{{ Number(total_washer).toLocaleString() }}</td>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 my-4" v-if="SaleByTypeDryer != ''">
          <h4 style="text-align: center;">{{ $t("message.dryer") }}</h4>

          <div id="chart" v-if="SaleByTypeDryer != ''">
            <apexchart type="line" height="350" :options="chartOptions_dryer" :series="series_dryer"></apexchart>
          </div>

          <div class="table-responsive" :style="daynameIntersection.length <= 3 ? 'padding-left: 20%; padding-right: 20%;' : 'padding-left: 15%; padding-right: 15%;' ">
            <table class="table-grid">
                <thead style="text-align-last: center">
                <th colspan="1">{{ $t("message.No") }}</th>
                <th colspan="1">{{ $t("message.productName") }}</th>
                <template v-for="row in daynameIntersection">
                  <th style="height: 50px; width: 8%;" v-if="row == 'Sunday'">{{$t('message.Sunday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Monday'">{{$t('message.Monday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Tuesday'">{{$t('message.Tuesday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Wednesday'">{{$t('message.Wednesday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Thursday'">{{$t('message.Thursday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Friday'">{{$t('message.Friday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Saturday'">{{$t('message.Saturday')}}</th>
                </template>
                <th colspan="1">{{ $t("message.tote") }}</th>
              </thead>
              <tbody>
                <template v-for="(saleData, index) in SaleByTypeDryer">
                    <tr>
                      <td style="text-align: center; ">
                        {{ index + 1}}
                      </td>
                      <td style="text-align: right; width: 25%; " >
                        {{ saleData.name }}
                      </td>
                      <template v-for="data in saleData.dryer">
                        <td style="text-align: right" >
                          {{ Number(data.total).toLocaleString() }}
                        </td>
                      </template>
                      <td style="text-align: right; width: 28%; " >
                        {{ Number(saleData.total).toLocaleString() }}
                      </td>
                    </tr>
                  </template>
                  <td style="width: 3%; text-align: right;"></td>
                  <td style="width: 35%; text-align: right;">{{ $t("message.tote") }}</td>
                  <template v-for="row in daynameIntersection">
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Sunday'">{{ Number(Sunday.dryer).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Monday'">{{ Number(Monday.dryer).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Tuesday'">{{ Number(Tuesday.dryer).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Wednesday'">{{ Number(Wednesday.dryer).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Thursday'">{{ Number(Thursday.dryer).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Friday'">{{ Number(Friday.dryer).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Saturday'">{{ Number(Saturday.dryer).toLocaleString() }}</td>
                  </template>
                  <td  style="height: 50px; width: 8%; text-align: right;">{{ Number(total_dryer).toLocaleString() }}</td>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 my-4" v-if="SaleByTypeSolution != ''">
          <h4 style="text-align: center;">{{ $t("message.solution") }}</h4>

          <div id="chart" v-if="SaleByTypeSolution != ''">
            <apexchart type="line" height="350" :options="chartOptions_solution" :series="series_solution"></apexchart>
          </div>

          <div class="table-responsive" :style="daynameIntersection.length <= 3 ? 'padding-left: 20%; padding-right: 20%;' : 'padding-left: 15%; padding-right: 15%;' ">
            <table class="table-grid">
              <thead style="text-align-last: center">
                <th colspan="1">{{ $t("message.No") }}</th>
                <th colspan="1">{{ $t("message.productName") }}</th>
                <template v-for="row in daynameIntersection">
                  <th style="height: 50px; width: 8%;" v-if="row == 'Sunday'">{{$t('message.Sunday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Monday'">{{$t('message.Monday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Tuesday'">{{$t('message.Tuesday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Wednesday'">{{$t('message.Wednesday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Thursday'">{{$t('message.Thursday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Friday'">{{$t('message.Friday')}}</th>
                  <th style="height: 50px; width: 8%;" v-if="row == 'Saturday'">{{$t('message.Saturday')}}</th>
                </template>
                <th colspan="1">{{ $t("message.tote") }}</th>
              </thead>
              <tbody>
                <template v-for="(saleData, index ) in SaleByTypeSolution">
                    <tr>
                      <td style="text-align: center; ">
                        {{ index + 1}}
                      </td>
                      <td style="text-align: right; width: 35%; " >
                        {{ saleData.name }}
                      </td>
                      <template v-for="data in saleData.solution">
                        <td style="text-align: right" >
                          {{ Number(data.total).toLocaleString() }}
                        </td>
                      </template>
                      <td style="text-align: right; width: 10%; " >
                        {{ Number(saleData.total).toLocaleString() }}
                      </td>
                    </tr>
                  </template>
                  <td style="width: 3%; text-align: right;"></td>
                  <td style="width: 35%; text-align: right;">{{ $t("message.tote") }}</td>
                  <template v-for="row in daynameIntersection">
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Sunday'">{{ Number(Sunday.solution).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Monday'">{{ Number(Monday.solution).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Tuesday'">{{ Number(Tuesday.solution).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Wednesday'">{{ Number(Wednesday.solution).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Thursday'">{{ Number(Thursday.solution).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Friday'">{{ Number(Friday.solution).toLocaleString() }}</td>
                    <td style="height: 50px; width: 8%; text-align: right;" v-if="row == 'Saturday'">{{ Number(Saturday.solution).toLocaleString() }}</td>
                  </template>
                  <td  style="height: 50px; width: 8%; text-align: right;">{{ Number(total_solution).toLocaleString() }}</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- แบ่งตามช่วงเวลา 3 ช่วง -->
      <div class="row">
        <div class="col-sm-6 col-md-12 col-lg-12 my-4"  v-if="series_range1[0].data != ''">
          <h4 style="text-align: center">{{ $t("message.salesByPeriod") }} 00:00:00 - 07:59:59 </h4>
          <div id="chart">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series_range1"></apexchart>
          </div>
        </div>

        <div class="col-sm-6 col-md-12 col-lg-12 my-4"  v-if="series_range2[0].data != ''">
          <h4 style="text-align: center">{{ $t("message.salesByPeriod") }} 08:00:00 - 15:59:59 </h4>
          <div id="chart">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series_range2"></apexchart>
          </div>
        </div>

        <div class="col-sm-6 col-md-12 col-lg-12 my-4"  v-if="series_range3[0].data != ''">
          <h4 style="text-align: center">{{ $t("message.salesByPeriod") }} 16:00:00 - 23:59:59 </h4>
          <div id="chart">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series_range3"></apexchart>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import webServices from "../../script";
import DateTimePicker from "../../components/DateTimePicker/MonthCustom";

import {
  MultiSelect,
  MultiListSelect,
} from "../../custom_modules/search-select";
import moment from "moment";
import VueJsonPretty from "vue-json-pretty";

export default {
  name: "orders",
  components: {
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty,
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      role_id: window.localStorage.getItem("roleID"),
      userBusiness: window.localStorage.getItem("business"),

      selectedProduct: [],
      selectedMachine: [],

      machineData: [],
      selectedId: {},
      searchData: {
        // datetime: "",
        datetime: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
      },

      series_overview: [
        {
          name: 'washer',
          type: "column",
          data: []
        }, {
          name: 'dryer',
          type: "column",
          data:  []
        }, {
          name: 'solution',
          type: "column",
          data:  []
        },
      ],
      chartOptions_overview: {
        colors: ["#3498DB", "#FEB019", "#775DD0", "#ff4560", "#00e396"],
        chart: {
          type: "line",
          height: 450,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1,
        },
      },

      // series_washer
      series_washer: [],
      chartOptions_washer: {
            //  colors: ["#AEC33A", "#FEC748", "#3498DB", "#9C27B0", "#D92139"],
        chart: {
          type: "line",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1,
        },
      },

      // series_dryer
      series_dryer: [],
      chartOptions_dryer: {
        chart: {
          type: "line",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1,
        },

      },

      series_solution: [],
      chartOptions_solution: {
        colors: ["#3498DB", "#00e396", "#FEB019", "#ff4560", "#9C27B0", "#19B3B1", "#AEC33A", "#FEC748", "#FFA289", "#706FA8"],
        chart: {
          type: "line",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1,
        },
      },

      series_range1: [
        { data: [] },
        { data: [] },
        { data: [] },
      ],

      series_range2: [
        { data: [] },
        { data: [] },
        { data: [] },
      ],

      series_range3: [
        { data: [] },
        { data: [] },
        { data: [] },
      ],

      chartOptions: {
        colors: ["#3498DB", "#FEB019", "#775DD0", "#ff4560", "#00e396"],
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },

      salesByDayname: {},
      salesByDayname_groupProductType: [],
      salesByDayname_groupProduct: [],

      washer: {
        totalAll: [],
        descriptionAll: [],
        description: [],
      },
      Sunday: {
        washer: 0, dryer: 0, solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },
      Monday: {
        washer: 0, dryer: 0, solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },
      Tuesday: {
        washer: 0,
        dryer: 0,
        solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },
      Wednesday: {
        washer: 0, dryer: 0, solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },
      Thursday: {
        washer: 0, dryer: 0, solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },
      Friday : {
        washer: 0, dryer: 0, solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },
      Saturday: {
        washer: 0, dryer: 0, solution: 0,
        range1:{ washer: 0, dryer: 0, solution: 0,},
        range2:{ washer: 0, dryer: 0, solution: 0,},
        range3:{ washer: 0, dryer: 0, solution: 0,},
      },

      washerNameIntersection: [],
      dryerNameIntersection:[],
      dryerExtensionNameIntersection:[],

      solutionNameIntersection:[],
      daynameIntersection: [],
      total_washer: 0,
      total_dryer: 0,
      total_solution: 0,

    };
  },
  computed: {
    SaleByTypeWasher() {
      const saleWasherList = this.salesByDayname_groupProduct.filter((data) => {
        return data.caseProductType === "washer";
      });

      const dayanme_list = [...new Set(saleWasherList.map(data => data.dayname))]
      const saleByTypeWasher = [];
      for (let i = 0; i < this.washerNameIntersection.length; i++) {
        let saleWasher = {
          name: '',
          total: 0,
          washer: [],
        };

        saleWasher.name = this.washerNameIntersection[i];
        const saleWasherListFilterByWasher = saleWasherList.filter(data => data.product.name === this.washerNameIntersection[i])

        let index = 0
        for (let j = 0; j < dayanme_list.length; j++) {
          const saleWasherTemp = saleWasherListFilterByWasher[index] ? saleWasherListFilterByWasher[index] : {}
          if(saleWasherTemp.dayname === dayanme_list[j]) {
            saleWasher.washer.push({
              dayname: dayanme_list[j],
              trans: saleWasherTemp.trans,
              total: saleWasherTemp.total,
            });
            saleWasher.total =  saleWasher.total + ( saleWasherTemp.total  ? saleWasherTemp.total  : 0  )
            index++
          } else {
            saleWasher.washer.push({
              dayname: dayanme_list[j],
              trans:0,
              total: 0
            });
          }
          saleWasher.productId = saleWasherList[j].product.productId;
        }
        saleByTypeWasher.push(saleWasher);
      }
      // console.log('saleByTypeWasher',saleByTypeWasher)

      saleByTypeWasher.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        } else if (a.total > b.total) {
          return -1;
        } else {
          return 0;
        }
      });


      this.series_washer = [];
      const list_name = [...new Set(saleByTypeWasher.map(data => data.name))]
      let count_list_name = list_name.length
      let index = 0

      // create array
      for (let o = 0; o < count_list_name ; o++) {
        if( o < 5){
          this.series_washer.push({name: '', data: [], type:'',});
        }
      }

      // insert data
      for (let x = 0; x < saleByTypeWasher.length; x++) {
        if(index < 5){
          for (let y = 0; y < dayanme_list.length; y++) {
            this.series_washer[index].type = "column"
            this.series_washer[index].name =  saleByTypeWasher[index].name
            this.series_washer[index].data.push(saleByTypeWasher[x].washer[y].total);
          }
        }
        index ++
      }

      return saleByTypeWasher;
    },

    SaleByTypeDryer() {
      const saleDryerList = this.salesByDayname_groupProduct.filter((data) => {
        return data.caseProductType === "dryer";
      });

      const dayanme_list = [...new Set(saleDryerList.map(data => data.dayname))]
      console.log(dayanme_list)
      const saleByTypeDryer = [];
      for (let i = 0; i < this.dryerNameIntersection.length; i++) {
        let saleDryer = {
          name: '',
          total: 0,
          dryer: [],
        };

        saleDryer.name = this.dryerNameIntersection[i];
        const saleDryerListFilterByDryer = saleDryerList.filter(data => data.product.name === this.dryerNameIntersection[i])

        let index = 0
        for (let j = 0; j < dayanme_list.length; j++) {
          const saleDryerTemp = saleDryerListFilterByDryer[index] ? saleDryerListFilterByDryer[index] : {}
          if(saleDryerTemp.dayname === dayanme_list[j]) {
            saleDryer.dryer.push({
              dayname: dayanme_list[j],
              trans: saleDryerTemp.trans,
              total: saleDryerTemp.total,
            });
            saleDryer.total =  saleDryer.total + ( saleDryerTemp.total  ? saleDryerTemp.total  : 0  )
            index++
          } else {
            saleDryer.dryer.push({
              dayname: dayanme_list[j],
              trans:0,
              total: 0
            });
          }
          saleDryer.productId = saleDryerList[j].product.productId;
        }
        saleByTypeDryer.push(saleDryer);
      }

      saleByTypeDryer.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        } else if (a.total > b.total) {
          return -1;
        } else {
          return 0;
        }
      });

      this.series_dryer = [];
      const list_name = [...new Set(saleByTypeDryer.map(data => data.name))]
      let count_list_name = list_name.length
      let index = 0

      // create array
      for (let o = 0; o < count_list_name ; o++) {
        if( o < 5){
          this.series_dryer.push({name: '', data: [], type:'',});
        }
      }

      // insert data
      for (let x = 0; x < saleByTypeDryer.length; x++) {
        if(index < 5){
          for (let y = 0; y < dayanme_list.length; y++) {
            this.series_dryer[index].type = "column"
            this.series_dryer[index].name =  saleByTypeDryer[index].name
            this.series_dryer[index].data.push(saleByTypeDryer[x].dryer[y].total);
          }
        }
        index ++
      }
      return saleByTypeDryer;
    },

    SaleByTypeSolution() {
       const saleSolutionList = this.salesByDayname_groupProduct.filter((data) => {
        return data.caseProductType === "solution";
      });

      const dayanme_list = [...new Set(saleSolutionList.map(data => data.dayname))]
      const saleByTypeSolution = [];
      for (let i = 0; i < this.solutionNameIntersection.length; i++) {
        let saleSolution = {
          name: '',
          total: 0,
          solution: [],
        };

        saleSolution.name = this.solutionNameIntersection[i];
        const saleSolutionListFilterBySolution = saleSolutionList.filter(data => data.product.name === this.solutionNameIntersection[i])

        let index = 0
        for (let j = 0; j < dayanme_list.length; j++) {
          const saleSolutionTemp = saleSolutionListFilterBySolution[index] ? saleSolutionListFilterBySolution[index] : {}
          if(saleSolutionTemp.dayname === dayanme_list[j]) {
            saleSolution.solution.push({
              dayname: dayanme_list[j],
              trans: saleSolutionTemp.trans,
              total: saleSolutionTemp.total,
            });
            saleSolution.total =  saleSolution.total + ( saleSolutionTemp.total  ? saleSolutionTemp.total  : 0  )
            index++

          } else {
            saleSolution.solution.push({
              dayname: dayanme_list[j],
              trans:0,
              total: 0
            });
          }
          saleSolution.productId = saleSolutionList[j].product.productId;
        }
        saleByTypeSolution.push(saleSolution);
      }

      saleByTypeSolution.sort(function (a, b) {
          if (a.total < b.total) {
            return 1;
          } else if (a.total > b.total) {
            return -1;
          } else {
            return 0;
          }
      });

      this.series_solution = [];
      const list_name = [...new Set(saleByTypeSolution.map(data => data.name))]
      let count_list_name = list_name.length
      let index = 0

      // create array
      for (let o = 0; o < count_list_name ; o++) {
        if( o < 10){
          this.series_solution.push({name: '', data: [], type:'',});
        }
      }

      // insert data
      for (let x = 0; x < saleByTypeSolution.length; x++) {
        if(index < 10){

          for (let y = 0; y < dayanme_list.length; y++) {
            this.series_solution[index].type = "column"
            this.series_solution[index].name =  saleByTypeSolution[index].name
            this.series_solution[index].data.push(saleByTypeSolution[x].solution[y].total);
          }
        }
        index ++
      }

      console.log('chartOptions_solution', this.chartOptions_solution.xaxis.categories)
      return saleByTypeSolution;
    },


  },
  async mounted() {
    await this.setParams(this.$route.query);
    await this.getMachine();

    if (
      !this.$isRole("admin", this.role_id) &&
      !this.$isRole("callcenter", this.role_id)
    ) {
      await this.getTransactions();
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    setParams(query) {
      if (Object.keys(query).length != 0) {
        // console.log(query)
        this.fromdate = query.from;
        this.todate = query.to;
        this.searchData.datetime = [query.from, query.to];
        this.searchData.productId = query.productId || "";
        this.searchData.machineId = query.machineId || "";
      }
    },

    async getMachine() {
      this.$Progress.start();
      await this.axios
        .get(`/machines/list`)
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData;
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    showAlert_Warning() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = "ไม่สามารถเลือกเดือนได้มากกว่า 6 เดือน"
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 6 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    async searchFn() {
      this.setupValue()
      console.log("selectedMachine", this.selectedMachine);
      console.log("searchData.datetime", this.searchData.datetime);

      var dateStart = new Date(this.searchData.datetime[0])
      var dateEnd = new Date(this.searchData.datetime[1])

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      if(this.monthDiff(dateStart, dateEnd) < 6){
        // console.log('เลือกสำเร็จ')
        await this.getSalesByDayname();
        await this.getSalesByDayname_GroupProductName();
        await this.getSalesByDayname_GroupDayName();
      }else{
        // console.log('กรุณาเลือใหม่')
        this.showAlert_Warning()
      }
    },

    async getSalesByDayname() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: (this.searchData.from = moment(this.searchData.datetime[0])
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        to: (this.searchData.to = moment(this.searchData.datetime[1])
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };
      await this.axios
        .get(`/analyze/salesbydayname`, { params })
        .then((res) => {
          this.salesByDayname = res.data.data;
          // console.log("salesByDayname ", this.salesByDayname);
          if (res.data) {
            // this.promationType = res.data
            let index = 0;
            let total_washer = 0
            let total_dryer = 0
            let total_solution = 0

            var daynameAll = []
            var daynameChart = []
            for (var i = 0; i < this.salesByDayname.length; i++) {
              daynameAll.push(this.salesByDayname[i].dayname)
              if(this.salesByDayname[i].caseProductType == 'washer'){
                if(this.salesByDayname[i].dayname == 'Sunday'){
                  this.Sunday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Monday'){
                  this.Monday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Tuesday'){
                  this.Tuesday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Wednesday'){
                  this.Wednesday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Thursday'){
                  this.Thursday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Friday'){
                  this.Friday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Saturday'){
                  this.Saturday.washer  = this.salesByDayname[i].total
                  this.series_overview[0].data.push(this.salesByDayname[i].total);
                }
                total_washer = total_washer + this.salesByDayname[i].total
              }

              if(this.salesByDayname[i].caseProductType == 'dryer'){
                if(this.salesByDayname[i].dayname == 'Sunday'){
                  this.Sunday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Monday'){
                  this.Monday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Tuesday'){
                  this.Tuesday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Wednesday'){
                  this.Wednesday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Thursday'){
                  this.Thursday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Friday'){
                  this.Friday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Saturday'){
                  this.Saturday.dryer  = this.salesByDayname[i].total
                  this.series_overview[1].data.push(this.salesByDayname[i].total);
                }
                total_dryer = total_dryer + this.salesByDayname[i].total
              }

              if(this.salesByDayname[i].caseProductType == 'solution'){
                if(this.salesByDayname[i].dayname == 'Sunday'){
                  this.Sunday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Monday'){
                  this.Monday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Tuesday'){
                  this.Tuesday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Wednesday'){
                  this.Wednesday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Thursday'){
                  this.Thursday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Friday'){
                  this.Friday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                if(this.salesByDayname[i].dayname == 'Saturday'){
                  this.Saturday.solution  = this.salesByDayname[i].total
                  this.series_overview[2].data.push(this.salesByDayname[i].total);
                }
                total_solution = total_solution + this.salesByDayname[i].total
              }

              if (i === this.salesByDayname.length - 1 || this.salesByDayname[i].dayname !== this.salesByDayname[i + 1].dayname) {
                index++;
                if (this.series_overview[0].data.length !== index) {
                  this.series_overview[0].data.push(0);
                }
                if (this.series_overview[1].data.length !== index) {
                  this.series_overview[1].data.push(0);
                }
                if (this.series_overview[2].data.length !== index) {
                  this.series_overview[2].data.push(0);
                }
              }
            }
            this.daynameIntersection = [...new Set(daynameAll)];
            daynameChart = [...new Set(daynameAll)];

            for (var x = 0; x < daynameChart.length; x++) {
              if(window.localStorage.getItem("language") == 'th'){
                if( daynameChart[x] === 'Sunday' ){ daynameChart[x] = 'อาทิตย์' }
                if( daynameChart[x] === 'Monday' ){ daynameChart[x] = 'จันทร์' }
                if( daynameChart[x] === 'Tuesday' ){ daynameChart[x] = 'อังคาร' }
                if( daynameChart[x] === 'Wednesday' ){ daynameChart[x] = 'พุธ' }
                if( daynameChart[x] === 'Thursday' ){ daynameChart[x] = 'พฤหัสบดี'}
                if( daynameChart[x] === 'Friday' ){ daynameChart[x] = 'ศุกร์' }
                if( daynameChart[x] === 'Saturday' ){ daynameChart[x] = 'เสาร์' }
              }else{
                if( daynameChart[x] === 'Sunday' ){ daynameChart[x] = 'Sunday' }
                if( daynameChart[x] === 'Monday' ){ daynameChart[x] = 'Monday' }
                if( daynameChart[x] === 'Tuesday' ){ daynameChart[x] = 'Tuesday' }
                if( daynameChart[x] === 'Wednesday' ){ daynameChart[x] = 'Wednesday' }
                if( daynameChart[x] === 'Thursday' ){ daynameChart[x] = 'Thursday'}
                if( daynameChart[x] === 'Friday' ){ daynameChart[x] = 'Friday' }
                if( daynameChart[x] === 'Saturday' ){ daynameChart[x] = 'Saturday' }
              }
            }

            this.total_washer = total_washer
            this.total_dryer = total_dryer
            this.total_solution = total_solution
            this.chartOptions.xaxis.categories =  daynameChart
            this.chartOptions_washer.xaxis.categories = daynameChart
            this.chartOptions_dryer.xaxis.categories = daynameChart
            this.chartOptions_solution.xaxis.categories = daynameChart


            if(window.localStorage.getItem("language") == 'th'){
              this.series_range1[0].name = 'ซักผ้า'
              this.series_range1[1].name = 'อกผ้า'
              this.series_range1[2].name = 'น้ำยา'

              this.series_range2[0].name = 'ซักผ้า'
              this.series_range2[1].name = 'อกผ้า'
              this.series_range2[2].name = 'น้ำยา'

              this.series_range3[0].name = 'ซักผ้า'
              this.series_range3[1].name = 'อกผ้า'
              this.series_range3[2].name = 'น้ำยา'
              this.chartOptions_overview.xaxis.categories = ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์ ', 'เสาร์', ]
            }else{
              this.series_range1[0].name = 'washer'
              this.series_range1[1].name = 'dryer'
              this.series_range1[2].name = 'solution'

              this.series_range2[0].name = 'washer'
              this.series_range2[1].name = 'dryer'
              this.series_range2[2].name = 'solution'

              this.series_range3[0].name = 'washer'
              this.series_range3[1].name = 'dryer'
              this.series_range3[2].name = 'solution'
              this.chartOptions_overview.xaxis.categories = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday ', 'Saturday', ]
            }
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    async getSalesByDayname_GroupDayName() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: (this.searchData.from = moment(this.searchData.datetime[0])
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        to: (this.searchData.to = moment(this.searchData.datetime[1])
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };
      await this.axios
        .get(`/analyze/salesbydayname/groupdayname`, { params })
        .then((res) => {
          this.salesByDayname_groupProductType = res.data.data;
          if (res.data) {
            let index = 0;
            for (var i = 0; i < this.salesByDayname_groupProductType.length; i++) {

              // ======================= Sunday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Sunday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Sunday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              // // ======================= Monday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Monday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Monday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              // // ======================= Tuesday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Tuesday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Tuesday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              // ======================= Wednesday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Wednesday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Wednesday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              // ======================= Thursday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Thursday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Thursday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              // ======================= Friday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Friday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Friday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              // ======================= Saturday =======================
              if(this.salesByDayname_groupProductType[i].dayname == 'Saturday'){
                if(this.salesByDayname_groupProductType[i].product.productType == 'washer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range1.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range2.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[0].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range3.washer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].product.productType == 'dryer'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range1.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range2.dryer  =  this.salesByDayname_groupProductType[i].total

                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[1].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range3.dryer  =  this.salesByDayname_groupProductType[i].total
                  }
                }

                if(this.salesByDayname_groupProductType[i].caseProductType == 'solution'){
                  if(this.salesByDayname_groupProductType[i].period == 'range1'){
                    this.series_range1[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range1.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range2'){
                    this.series_range2[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range2.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                  if(this.salesByDayname_groupProductType[i].period == 'range3'){
                    this.series_range3[2].data.push(this.salesByDayname_groupProductType[i].total);
                    this.Saturday.range3.solution  =  this.salesByDayname_groupProductType[i].total
                  }
                }
              }

              if (i === this.salesByDayname_groupProductType.length - 1 || this.salesByDayname_groupProductType[i].dayname !== this.salesByDayname_groupProductType[i + 1].dayname) {
                index++;
                if (this.series_range1[0].data.length !== index) {
                  this.series_range1[0].data.push(0);
                }
                if (this.series_range1[1].data.length !== index) {
                  this.series_range1[1].data.push(0);
                }
                if (this.series_range1[2].data.length !== index) {
                  this.series_range1[2].data.push(0);
                }

                if (this.series_range2[0].data.length !== index) {
                  this.series_range2[0].data.push(0);
                }
                if (this.series_range2[1].data.length !== index) {
                  this.series_range2[1].data.push(0);
                }
                if (this.series_range2[2].data.length !== index) {
                  this.series_range2[2].data.push(0);
                }

                if (this.series_range3[0].data.length !== index) {
                  this.series_range3[0].data.push(0);
                }
                if (this.series_range3[1].data.length !== index) {
                  this.series_range3[1].data.push(0);
                }
                if (this.series_range3[2].data.length !== index) {
                  this.series_range3[2].data.push(0);
                }

              }
            }
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    async getSalesByDayname_GroupProductName() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: (this.searchData.from = moment(this.searchData.datetime[0])
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        to: (this.searchData.to = moment(this.searchData.datetime[1])
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };
      await this.axios
        .get(`/analyze/salesbydayname/groupproductname`, { params })
        .then((res) => {
          this.salesByDayname_groupProduct = res.data.data;
          if (res.data) {
            var washerNameAll = []
            var dryerNameAll = []
            var solutionNameAll = []

            for (let i = 0; i < this.salesByDayname_groupProduct.length; i++){
              if(this.salesByDayname_groupProduct[i].caseProductType == 'washer'){
                washerNameAll.push(this.salesByDayname_groupProduct[i].product.name)
              }
              if(this.salesByDayname_groupProduct[i].caseProductType == 'dryer'){
                dryerNameAll.push(this.salesByDayname_groupProduct[i].product.name)
              }
              if(this.salesByDayname_groupProduct[i].caseProductType == 'solution'){
                solutionNameAll.push(this.salesByDayname_groupProduct[i].product.name)
              }
            }
            this.washerNameIntersection = [...new Set(washerNameAll)];
            this.dryerNameIntersection = [...new Set(dryerNameAll)];
            this.solutionNameIntersection = [...new Set(solutionNameAll)];
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },

    onSelect(items) {
      // console.log(items)
      this.selectedProduct = items;
      this.searchData.productId = this.selectedProduct.map((data) => data.id);
    },

    changeTab(params) {
      this.rowData = {};
      this.totalRows = 0;
      this.totalSubRows = 0;
      this.summary = {};
      this.searchData.type = params;
      if (
        !this.$isRole("admin", this.role_id) &&
        !this.$isRole("callcenter", this.role_id)
      ) {
        this.searchFn();
      }
    },

    setupValue(){
      this.chartOptions_washer.xaxis.categories = [];
      this.chartOptions_dryer.xaxis.categories = [];
      this.chartOptions_solution.xaxis.categories = [];
      this.salesByDayname_groupProduct = ''

      this.Sunday.washer = 0
      this.Monday.washer = 0
      this.Tuesday.washer = 0
      this.Wednesday.washer = 0
      this.Thursday.washer = 0
      this.Friday.washer = 0
      this.Saturday.washer = 0

      this.Sunday.dryer = 0
      this.Monday.dryer = 0
      this.Tuesday.dryer = 0
      this.Wednesday.dryer = 0
      this.Thursday.dryer = 0
      this.Friday.dryer = 0
      this.Saturday.dryer = 0

      this.Sunday.solution = 0
      this.Monday.solution = 0
      this.Tuesday.solution = 0
      this.Wednesday.solution = 0
      this.Thursday.solution = 0
      this.Friday.solution = 0
      this.Saturday.solution = 0

      // =========================================
      // ================ range 1 ================
      // =========================================
      this.Sunday.range1.washer = 0;
      this.Sunday.range1.dryer = 0;
      this.Sunday.range1.solution = 0;

      this.Monday.range1.washer = 0;
      this.Monday.range1.dryer = 0;
      this.Monday.range1.solution = 0;

      this.Tuesday.range1.washer = 0;
      this.Tuesday.range1.dryer = 0;
      this.Tuesday.range1.solution = 0;

      this.Wednesday.range1.washer = 0;
      this.Wednesday.range1.dryer = 0;
      this.Wednesday.range1.solution = 0;

      this.Thursday.range1.washer = 0;
      this.Thursday.range1.dryer = 0;
      this.Thursday.range1.solution = 0;

      this.Friday.range1.washer = 0;
      this.Friday.range1.dryer = 0;
      this.Friday.range1.solution = 0;

      this.Saturday.range1.washer = 0;
      this.Saturday.range1.dryer = 0;
      this.Saturday.range1.solution = 0;
;

      // =========================================
      // ================ range 2 ================
      // =========================================
      this.Sunday.range2.washer = 0;
      this.Sunday.range2.dryer = 0;
      this.Sunday.range2.solution = 0;

      this.Monday.range2.washer = 0;
      this.Monday.range2.dryer = 0;
      this.Monday.range2.solution = 0;

      this.Tuesday.range2.washer = 0;
      this.Tuesday.range2.dryer = 0;
      this.Tuesday.range2.solution = 0;

      this.Wednesday.range2.washer = 0;
      this.Wednesday.range2.dryer = 0;
      this.Wednesday.range2.solution = 0;

      this.Thursday.range2.washer = 0;
      this.Thursday.range2.dryer = 0;
      this.Thursday.range2.solution = 0;

      this.Friday.range2.washer = 0;
      this.Friday.range2.dryer = 0;
      this.Friday.range2.solution = 0;

      this.Saturday.range2.washer = 0;
      this.Saturday.range2.dryer = 0;
      this.Saturday.range2.solution = 0;

      // =========================================
      // ================ range 3 ================
      // =========================================
      this.Sunday.range3.washer = 0;
      this.Sunday.range3.dryer = 0;
      this.Sunday.range3.solution = 0;

      this.Monday.range3.washer = 0;
      this.Monday.range3.dryer = 0;
      this.Monday.range3.solution = 0;

      this.Tuesday.range3.washer = 0;
      this.Tuesday.range3.dryer = 0;
      this.Tuesday.range3.solution = 0;

      this.Wednesday.range3.washer = 0;
      this.Wednesday.range3.dryer = 0;
      this.Wednesday.range3.solution = 0;

      this.Thursday.range3.washer = 0;
      this.Thursday.range3.dryer = 0;
      this.Thursday.range3.solution = 0;

      this.Friday.range3.washer = 0;
      this.Friday.range3.dryer = 0;
      this.Friday.range3.solution = 0;

      this.Saturday.range3.washer = 0;
      this.Saturday.range3.dryer = 0;
      this.Saturday.range3.solution = 0;

      this.series_overview[0].data = [];
      this.series_overview[1].data = [];
      this.series_overview[2].data = [];

      this.series_range1[0].data = [];
      this.series_range1[1].data = [];
      this.series_range1[2].data = [];

      this.series_range2[0].data = [];
      this.series_range2[1].data = [];
      this.series_range2[2].data = [];

      this.series_range3[0].data = [];
      this.series_range3[1].data = [];
      this.series_range3[2].data = [];
    }
  },
};
</script>
